import React from "react"
import { graphql } from "gatsby"
import {Col, Container, Row} from "react-bootstrap"
import {IPage} from "../types";
import { GatsbyImage } from "gatsby-plugin-image";
import {Header} from "../components/Header";
import {Bouwtype} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import Prijslijst from "gatsby-theme-nieuwbouw/src/components/prijslijst";
import {ChildImageSharp} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.markdownRemark.frontmatter.title} pageDescription={data.markdownRemark.frontmatter.description}>
            <Header image={data.desktopImage.childImageSharp.gatsbyImageData} />
            <Container fluid={'lg'}>
                <div
                    style={{
                        position: 'relative',
                        zIndex: 21,
                    }}
                    className={"bg-white mt-n5 p-5"}
                >
                    {data.allBouwtype.nodes.map(bouwtype =>
                        <Row key={bouwtype.id} className={"mb-5"}>
                            <Col md={6}>
                                <h3>
                                    <span className={'bg-primary'}>Type {bouwtype.algemeen.omschrijving}</span>
                                </h3>
                                {bouwtype.hoofdfoto &&
                                <GatsbyImage
                                    alt={"artist impression"}
                                    image={bouwtype.hoofdfoto.localFile.childImageSharp.gatsbyImageData} />
                                }
                            </Col>
                            <Col md={6}>
                                <h3>
                                    {bouwtype.bouwnummers && bouwtype.bouwnummers.length} woningen
                                </h3>
                                <Prijslijst bouwnummers={bouwtype.bouwnummers || []}/>
                                <p>
                                    Let op: voor deze woning geldt een zelfbewoningsverplichting, u mag deze dus niet
                                    verhuren
                                </p>
                            </Col>
                        </Row>
                    )}

                    <div dangerouslySetInnerHTML={{__html: data.markdownRemark.html}} />
                </div>
            </Container>
        </Page>
    );
}

interface PageQueryData {
    allBouwtype: {
        nodes: Bouwtype[]
    }
    markdownRemark: IPage
    desktopImage: ChildImageSharp
}

export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: {slug: {eq: "beschikbaar"}}) {
            ...Page
        }
        allBouwtype(sort: {fields: algemeen___omschrijving}) {
            nodes {
                id
                ...Bouwtype
                bouwnummers {
                    id
                    ...BouwnummerModal
                }
                diversen {
                    objectcode
                }
            }
        }
        desktopImage: file(relativePath: {eq: "sfeer/nieuw hoofdbeeld-pagina Assen.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    aspectRatio: 4
                    transformOptions: {cropFocus: ATTENTION}
                    layout: FULL_WIDTH
                )
            }
        }
    }
`
